<template>
  <div class="header">
    <slot></slot>
  </div>
</template>
<script>
export default{
    name:"C-header",
    props: {},
  components: {},

  
}

</script>
<style scoped>
.header{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 10vh;
    background-color: VAR(--header-bg-color);
    padding: 5px;
}
</style>
