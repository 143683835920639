export const urlLogin =
"/api/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/";
export const common = {
  methods: {
    imgToBase64(e) {
      return new Promise((resolve, reject) => {
        const file = e[0].originFileObj;
        let fileName = e[0].originFileObj.name;
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const base64Image = e.target.result;
            let base = base64Image.split(',')[1];
            resolve([base,fileName]);
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        } else {
          reject(new Error("No file selected"));
        }
      });
    },
  },
};

