// import { inject } from "vue";
import axios from 'axios';
export const request = {
  methods: {
    //=======================================get======================================
    async requestGet(url, stateCode) {
    //   let REQUEST = inject("axios");
    // url = "/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/"
      try {
        const response = await axios.get(url);
        console.log("resss:",response);

        if (response.data.stateCode === stateCode || response.data.returnCode === stateCode) {
          // resContent.value = response.data.content;
          return { response: response };
        }else{
            //弹窗错误信息
        }
      } catch (error) {
        console.error(error);
      }
    },
    //=======================================post======================================
    async requestPost(url,body,stateCode) {
       url = "/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/"

      try {
        const response = await axios.post(url,body);
        console.log("resss:",response);
        
        if (response.data.stateCode === stateCode || response.data.returnCode === stateCode) {
          // resContent.value = response.data.content;
          return { response: response};
        }else{
            //弹窗错误信息
        }
      } catch (error) {
        console.error(error);
      }
    },

    
  },
};
