<template>
  <!-- <h3 style="text-align: center;">地址信息</h3> -->
  <!-- <el-input type="text" readonly hidden/>用于存序号，需不需要这种方式存还待定-->
  <a-tabs
    v-model:activeKey="activeKey"
    type="editable-card"
    @edit="onEdit"
    @change="handleTabChange"
  >
    <a-tab-pane
      v-for="(pane, ind) in panes"
      :key="pane.key"
      :tab="pane.title"
      :closable="pane.closable"
    >
      <form class="form">
        <div class="input-box">
          <span class="span-tit">*地址类型</span
          ><el-select
            v-model="resLogin.form['address-info'][ind].ADDRESS_TYPE"
            placeholder="请选择"
          >
            <el-option
              :value="option.address_type"
              v-for="(option, index) in selectList.address_type_list"
              :key="index"
              :label="option.addr_type_descr"
            >
            </el-option></el-select
          ><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*国家地区</span>
          <el-select v-model="resLogin.form['address-info'][ind].COUNTRY">
            <el-option
              :value="option.country"
              v-for="(option, index) in selectList.country"
              :key="index"
              :label="option.descr"
              :default="chn"
            >
            </el-option>
          </el-select>
          <!-- <el-autocomplete
            v-model="searchText[tabsIndex]"
            :fetch-suggestions="querySearch"
            class="inline-input w-50"
            placeholder="支持输入查询"
            @select="handleSelect(ind, $event)"
            :value-key="value"
            submit-on-enter
            clearable
          /> -->
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">城市</span>
          <!-- <el-select v-model="resLogin.form['address-info'][ind].CITY">
            <el-option
              :value="option.location"
              v-for="(option, index) in selectList.city_list"
              :key="index"
            >
              {{ option.descr }}
            </el-option>
            </el-select> -->
          <el-autocomplete
            v-model="searchText1[tabsIndex]"
            :fetch-suggestions="querySearch1"
            class="inline-input w-50"
            placeholder="支持输入查询"
            @select="handleSelect1(ind, $event)"
            :value-key="value"
            submit-on-enter
            clearable
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">州/省</span
          ><el-select
            v-model="resLogin.form['address-info'][ind].STATE"
            placeholder="请选择"
          >
            <el-option
              :value="option.state"
              v-for="(option, index) in selectList.state_list"
              :key="index"
              :label="option.descr"
            >
            </el-option></el-select
          ><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">县/市</span>
          <el-input
            type="text"
            maxlength="30"
            v-model="resLogin.form['address-info'][ind].COUNTY"
            placeholder="请输入"
          />
          <span class="descr"></span>
        </div>

        <div class="input-box">
          <span class="span-tit">*详细地址</span
          ><el-input
            type="text"
            maxlength="55"
            v-model="resLogin.form['address-info'][ind].ADDRESS1"
            placeholder="请选择"
          /><span class="descr"></span>
        </div>
      </form>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { useStore } from "vuex";
import { ref, reactive, onMounted } from "vue";
import { computed } from "vue";
export default {
  name: "family",
  props: {},
  setup() {
    const panes = ref([]);
    const store = useStore();
    const searchText = ref([]);
    const searchText1 = ref([]);
    const chn = "CHN"
    //取值state
    const resLogin = computed(() => {
      return store.state.userInfo.resLogin;
    });
    //取值state
    const selectList = computed(() => {
      return store.state.myValue;
    });
    // layui标签页
    let index = 1; //地址信息编号
    // ============根据返回的数据看数组的长度在页面上遍历页签，空数组的话就自动生成一个页签==
    if (resLogin.value.form["address-info"].length == 0) {
      panes.value.push({
        title: `地址信息`,
        content: "",
        key: index,
      });
      index = index + 1;
    } else {
      //遍历所有数据，有几条数据就生成几个tab
      for (let i = 0; i < resLogin.value.form["address-info"].length; i++) {
        if(i==0){
        panes.value.push({
          title: `地址信息`,
          content: "",
          key: `${index}`,
          closable:false

        });
      }else{
        panes.value.push({
          title: `地址信息`,
          content: "",
          key: `${index}`,
        });

      }
        index = index + 1;
      }
    }
    //=================

    const activeKey = ref(panes.value[0].key);
    const newTabIndex = ref(0);
    //添加页签
    const add = () => {
      let address_info = {
        DC_CANDDT_ID: resLogin.value.form["base-info"].DC_CANDDT_ID,
        ADDRESS_TYPE: "",
        COUNTRY: "",
        CITY: "",
        STATE: "",
        COUNTY: "",
        ADDRESS1: "",
      };
      activeKey.value = index;
      panes.value.push({
        title: `地址信息`,
        content: "",
        key: activeKey.value,
      });
      index = index + 1;
      resLogin.value.form["address-info"].push(address_info);
      console.log(resLogin.value.form["address-info"]);

      // resLogin.value.form["relative"][index].DEPENDENT_BENEF = `${index}`;
      store.dispatch("userInfo/setUserData", resLogin);
    };
    //删除页签
    const remove = (targetKey) => {
      console.log("targetkey:", targetKey);

      let lastIndex = 0;
      panes.value.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      panes.value = panes.value.filter((pane) => pane.key !== targetKey);
      if (panes.value.length && activeKey.value === targetKey) {
        if (lastIndex >= 0) {
          activeKey.value = panes.value[lastIndex].key;
        } else {
          activeKey.value = panes.value[0].key;
        }
      }
      index = index - 1;
      resLogin.value.form["address-info"].splice(targetKey - 1, 1);

      store.dispatch("userInfo/setUserData", resLogin);
    };

    store.commit("setChildData", resLogin.value.form);
    const onEdit = (targetKey, action) => {
      console.log("on", targetKey);

      if (action === "add") {
        add();
      } else {
        remove(targetKey);
      }
    };

    const tabsIndex = ref(0);
    const handleTabChange = (key, event) => {
      tabsIndex.value = key - 1;
      console.log(tabsIndex.value);
      getData();
      getData1();
    };
    const getData = () => {
      selectList.value.country.filter((i) => {
        if (
          i.country ==
          resLogin.value.form["address-info"][tabsIndex.value].COUNTRY
        ) {
          searchText.value[tabsIndex.value] = i.descr;
        }
      });
    };

    const getData1 = () => {
      selectList.value.city_list.filter((i) => {
        if (
          i.location ==
          resLogin.value.form["address-info"][tabsIndex.value].CITY
        ) {
          searchText1.value[tabsIndex.value] = i.descr;
        }
      });
    };

    onMounted(() => {
      // resLogin.value.form['base-info'].CONTRIB_AREA_CHN
      getData();
      getData1();
      selectList.value.country.forEach((ele) => {
        ele.value = ele.descr;
      });
      restaurants.value = loadAll();

      selectList.value.city_list.forEach((ele) => {
        ele.value = ele.descr;
      });
      restaurants1.value = loadAll1();
    });

    const restaurants = ref([{}]);
    const restaurants1 = ref([{}]);

    const querySearch = (queryString, cb) => {
      const results = queryString
        ? restaurants.value.filter((option) =>
            option.value.includes(queryString)
          )
        : restaurants.value;
      //  ?  restaurants.value.filter((restaurant) => {
      //     return (
      //       restaurant.value
      //         .toLowerCase()
      //         .indexOf(queryString.toLowerCase()) != -1
      //     );
      //   })
      // : restaurants.value;
      cb(results);
    };

    const loadAll = () => {
      return selectList.value.country;
    };

    const querySearch1 = (queryString, cb) => {
      const results = queryString
        ? restaurants1.value.filter((option) =>
            option.value.includes(queryString)
          )
        : restaurants1.value;
      //  ?  restaurants.value.filter((restaurant) => {
      //     return (
      //       restaurant.value
      //         .toLowerCase()
      //         .indexOf(queryString.toLowerCase()) != -1
      //     );
      //   })
      // : restaurants.value;
      cb(results);
    };

    const loadAll1 = () => {
      return selectList.value.city_list;
    };
    const handleSelect = (index, item) => {
      console.log(item, "======", index);
      searchText.value[index] = item.value;
      resLogin.value.form["address-info"][index].COUNTRY = item.country;
    };
    const handleSelect1 = (index, item) => {
      console.log(item, "======", index);
      searchText1.value[index] = item.value;
      resLogin.value.form["address-info"][index].CITY = item.location;
    };

    return {
      resLogin,
      index,
chn,
      add,
      remove,
      onEdit,
      panes,
      selectList,
      restaurants,
      querySearch,
      loadAll,
      handleSelect,
      searchText,
      getData,
      searchText1,
      getData1,
      querySearch1,
      loadAll1,
      handleSelect1,
      handleTabChange,
      tabsIndex,
    };
    // console.log(store.state.userInfo.resLogin,"resLogin")
  },
};
</script>
<style scoped>
/* 自己的样式 */

.form {
  font-size: var(--pc-text-size-title);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  line-height: 45px;
  padding: 15px;
  /* padding-right: 50px; */

  .file-box {
    width: 100%;
  }
  .input-box {
    /* min-width: 100px; */
    width: calc(100% / 3);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 0 3px 0;
    .span-tit {
      width: 150px;
      /* display: flex;
      justify-content: end; */
      line-height: 17px;
    }
    input,
    select {
      width: 200px;
      border: 1px solid rgb(199, 199, 199);
      height: 25px;
      border-radius: 3px;
      font-size: 12px;
      margin-left: 5px;
      /* -webkit-appearance:unset !important ; */
      option {
        height: 25px;
      }
    }

    .descr {
      width: 195px;
      word-wrap: break-word;
      line-height: 17px;
      margin-left: 5px;
    }
  }
}
@media only screen and (max-width: 768px) {
  /* 手机端适配 */
  .form {
    gap: 10px;
    .input-box {
      gap: 10px;
      width: 100%;
      .span-tit {
        /* width: 40%; */
      }
      input,
      select {
        width: 80%;
        height: 20px;
        font-size: 10px;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* 平板电脑的样式 */
}
</style>
