<template>
    <lay-loading
    v-if="loading"
    :type="2"
    :loading="loading"
    tip="loading..."
    style="position: fixed; height: 100vh; opacity: 0.5; z-index: 99999"
  ></lay-loading>
  <router-view></router-view>
 
</template>

<script setup>
import { computed } from 'vue';
import store from './store/index';


const loading = computed(()=>{
  return store.state.loading
})

</script>

<style>
form,.el-input__wrapper,.el-select__wrapper,.el-textarea__inner{
  font-size: 1rem !important;
}
:root {
  --button-color: rgb(215, 0, 14);
  --button-hover-color:rgba(215, 0, 14, 0.63);
  --header-bg-color: rgb(215, 0, 14);
  --bg-color: rgb(220, 227, 235, 60%);
  --bg-content-color: white;
  --button-text-color: rgb(255, 255, 255) !important;
  --bg-color-deep: rgb(220, 227, 235, 40%);
  --color-white:white;
  --color-red-one:rgb(215, 0, 14,80%);
  --color-red-two:rgb(215, 0, 14,60%);
  --color-red-three:rgb(215, 0, 14,20%);
  --color-text-black:black;
  --bg-low-white:rgba(238, 238, 238);
  --warning-color-red:red;
/* 文字 */
  --warning-text-size:10px;
  --pc-text-size-title:1rem;
}

@media only screen and (max-width: 768px) {
  /* 手机端适配 */

  :root{
  }
  .btn-up-label{
  max-width: 300px;
      height: 20px;

  }
  .input-box{
  display:block !important;
 }



}
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* 平板电脑的样式 */

}
.btn-up-label{
  display: block;
  max-width: 300px;
  /* min-width: 200px; */
      border: 2px solid rgb(237, 237, 237);
      height: 30px;
      border-radius: 5px;
      font-size: 12px;
      margin-left: 5px;
      background-color: var(--color-red-three);
align-items: center; 
     display: flex;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

}


.input,textarea,.el-input__wrapper,.el-select__wrapper,.el-textarea__inner{
  caret-color:rgb(122, 122, 122);
  transition: border-color 0.1s ease-in-out;
  height: 35px;
  /* margin-left: 5px; */
}


.el-textarea__inner.is-focus,.el-input__wrapper.is-focus,.el-select__wrapper.is-focused{
  border:2px solid var(--color-red-one) !important ;
box-shadow: none !important;
}
.el-textarea__inner.is-focus,.el-input__wrapper.is-focus,.el-select__wrapper.is-focused{
  border:2px solid var(--color-red-one) !important ;
box-shadow: none !important;
}
.button {
  background: var(--button-color) !important;
  border-radius: 3px !important;
  color: var(--button-text-color) !important;
}
.button:hover{
  background-color:var(--button-hover-color) !important;
  color: var(--button-text-color) !important;
}

.btn-large {
  border: none;
  height: 50px;
  width: 100px;
}
.btn-middle {
  border: none;
  height: 35px;
  width: 70px;
}
.btn-small {
  border: none;
  height: 30px;
  width: 50px;
}
</style>
