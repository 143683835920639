<template>
  <div class="body-box">
<slot name="Menu"></slot>
<slot name="content"></slot>


  </div>
</template>
<script>

export default {
  name: "C-body",
  props: [],
  components: {},
  
};
</script>
<style scoped>
.body-box {
  display: flex;
  flex-direction: row;
}
</style>
