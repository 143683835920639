export default {
    //模块化写法-- 功能区分开，一个功能写一个store模块
    namespaced: true, //开启模块化
    state: {
      resLogin:{},//用户信息
      attach:{},//附件信息
    },
    mutations: {
      SET_USER_DATA(state,payload){
        state.resLogin = payload
      },
      SET_USER_ATTACH(state,payload){
        state.attach = payload

      },
      //添加标签页时触发
      PUSH_TAB_DATE(state,payload){

        state.resLogin.form[`${payload.type}`].push({})

      },
    },
    actions: {
      setUserData({commit},data){
        commit("SET_USER_DATA",data)
      },
      setUserAttach({commit},data){
        commit("SET_USER_ATTACH",data)
      },
      //添加标签页时触发
      pushTabDate({commit},data){
        const {resLogin,type} = data
        commit("PUSH_TAB_DATE",{resLogin,type})
      }
    },
 

    getters:{

    }
  };
  