<template>
  <div class="left-box">
    <MenuLeft></MenuLeft>
  </div>
</template>

<script>
import MenuLeft from "@/layout/MenuLeft.vue";
export default {
  name: "C-left",
  props: {},
  components: {
    MenuLeft,
  },
};
</script>

<style scoped>
.left-box {
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 10%;
  background-color: var(--bg-color-deep);
  overflow-y: scroll;
  /* 对于 WebKit 内核浏览器（如 Chrome、Safari 等） */
  -webkit-scrollbar: none;
  /* 对于火狐浏览器 */
  scrollbar-width: none;
  padding: 3px 0 0 3px;
}
@media only screen and (max-width: 768px) {
  .left-box{
    width: 20%;
  }
}


</style>
