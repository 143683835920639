<template>
  <div class="content-box">
    <div class="content">
      <!-- 路由出口 -->
      <!-- <em class="blink-text" style="width: 100%;text-align: center;display: block;margin-top: 30px;">请选择要填写的内容！</em> -->
      <keep-alive>
        <router-view> </router-view>
      </keep-alive>
    </div>
    <div class="buttons-box">
      <button class="btn-middle button" @click="save">保存</button>
      <button class="btn-middle button" @click="submitForm">提交</button>
    </div>
    <em
      style="
        display: block;
        width: 100%;
        text-align: center;
        color: var(--color-red-one);
      "
      >*关闭浏览器将会失去未保存的信息，请及时保存</em
    >
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { onMounted, ref, reactive, watch, computed } from "vue";
import { useStore } from "vuex";
import { request } from "../mixin/axios";
import { ElMessage, ElMessageBox } from "element-plus";

const router = useRouter();
const store = useStore();
const parentData = computed(() => store.state.childData);
// const fileImgList = computed(() => store.state.fileImgList);

watch(
  () => router.currentRoute.value,
  () => {}
);
const addressInfo_save = computed(() => {
  return parentData.value["address-info"].some((item) => !item.ADDRESS_TYPE);
});
// const jobQualification_save = computed(() => {
//   return parentData.value["professional-qual"].some((item) => !item.DC_PRQUID);
// });
const beforeWorkInfo_save = computed(() => {
  return parentData.value["work-exp"].some((item) => !item.START_DT);
});
const educationInfo_save = computed(() => {
  return parentData.value["edu-exp"].some((item) => !item.BEGIN_DT);
});
const familyInfo_save = computed(() => {
  return parentData.value["relative"].some(
    (item) => !item.LAST_NAME || !item.FIRST_NAME
  );
});

const userBankInfo_save = computed(() => {
  return parentData.value["bank-info"].some(
    (item) => !item.ACCOUNT_EC_ID || !item.BRANCH_EC_CD
  );
});
const basicInfo = computed(() => {
  if (
    parentData.value["base-info"].DC_APP_ROOM &&
    parentData.value["base-info"].DC_APP_ROOM == "Y"
  ) {
    if (
      !(
        parentData.value["base-info"].DC_ROOM_DATE &&
        parentData.value["base-info"].DC_ROOM_ADRESS2
      )
    ) {
      return true;
    }
  }
  if (
    parentData.value["base-info"].DC_INTL_STD &&
    parentData.value["base-info"].DC_INTL_STD == "Y"
  ) {
    if (!parentData.value["base-info"].DC_INTL_STD_DATE) {
      return true;
    }
  }
  return (
    !parentData.value["base-info"].LAST_NAME ||
    !parentData.value["base-info"].FIRST_NAME ||
    !parentData.value["base-info"].PREF_FIRST_NAME ||
    !parentData.value["base-info"].NATIONAL_ID_TYPE ||
    !parentData.value["base-info"].NATIONAL_ID ||
    !parentData.value["base-info"].YESNO_DROPDOWN ||
    !parentData.value["base-info"].SEX ||
    !parentData.value["base-info"].EMAIL_ADDR ||
    !parentData.value["base-info"].PHONE ||
    !parentData.value["base-info"].MAR_STATUS ||
    !parentData.value["base-info"].FT_STUDENT ||
    !parentData.value["base-info"].DC_INTL_STD ||
    !parentData.value["base-info"].DC_APP_ROOM ||
    !parentData.value["base-info"].HUKOU_TYPE_CHN ||
    !parentData.value["base-info"].CONTRIB_AREA_CHN ||
    !parentData.value["base-info"].FLAG1 ||
    !parentData.value["base-info"].FLAG
  ); //|| !item.field2|| !item.field2
});
const familyInfo = computed(() => {
  return parentData.value["relative"].some(
    (item) =>
      !item.DC_YES_OR_NO ||
      !item.LAST_NAME ||
      !item.FIRST_NAME ||
      !item.RELATIONSHIP ||
      !item.ADDRESS1 ||
      !item.PHONE
  ); //|| !item.field2|| !item.field2
});
const rpInfo = computed(() => {
  return parentData.value["reward-punishment"].some(
    (item) =>
      !item.DC_RANDPNAME ||
      !item.DC_RANDPCATE ||
      !item.DC_RANDPLV ||
      !item.CURRENCY_CD ||
      !item.DC_COMMENTS ||
      !item.DC_RANDPTARGET
  ); //|| !item.field2|| !item.field2
});

const addressInfo = computed(() => {
  return parentData.value["address-info"].some(
    (item) => !item.ADDRESS_TYPE || !item.COUNTRY || !item.ADDRESS1
  ); //|| !item.field2|| !item.field2
});
const beforeWorkInfo = computed(() => {
  return parentData.value["work-exp"].some(
    (item) =>
      !item.START_DT ||
      !item.EMPLOYER ||
      !item.ENDING_TITLE ||
      !item.CURRENCY_CD ||
      !item.CURRENCY_CD ||
      !item.DC_IS_INTERSHIP_EX
  ); //|| !item.field2|| !item.field2
});

const educationInfo = computed(() => {
  return parentData.value["edu-exp"].some(
    (item) =>
      !item.BEGIN_DT ||
      !item.END_DT ||
      !item.SCHOOL ||
      !item.DC_LEARN_FORMS ||
      !item.MAJOR ||
      !item.DC_MAJOR_TYPE ||
      !item.DC_EDU_EDUCATION ||
      !item.DC_IS_HIGH_EDU ||
      !item.DC_GRADUATE_NUMBER ||
      !item.DC_GRADUATE_DATE ||
      !item.DC_IS_HIGH_DEGREE
  ); //|| !item.field2|| !item.field2
});
const jobTitileInfo = computed(() => {
  let jobTit = false;
  if (parentData.value["base-info"].FLAG == "Y") {
    jobTit = parentData.value["job-title"].some(
      (item) =>
        !item.DC_TITLE_NAME ||
        !item.DC_TITLE_TYPE ||
        !item.DC_TITLE_LEVEL ||
        !item.DC_CERT_NUM ||
        !item.DC_ISS_DATE ||
        !item.DC_ISS_UNIT
    ); //|| !item.field2|| !item.field2
  }
  return jobTit;
});
const jobQualification = computed(() => {
  return parentData.value["professional-qual"].some(
    (item) => !item.DC_QUALIFILV || !item.DC_ISTOPLV || !item.DATE
  ); //|| !item.field2|| !item.field2
});
const language = computed(() => {
  let lan = false;
  if (parentData.value["base-info"].FLAG == "Y") {
    lan = parentData.value["language"].some(
      (item) =>
        !item.LANG_CD ||
        // !item.DC_LANGUAGE_LEVEL ||
        !item.DC_AUTH_NAME ||
        !item.DC_PROVE_ID ||
        !item.DC_AUTH_NUMBER ||
        !item.DC_ISS_UNIT
    ); //|| !item.field2|| !item.field2
  }
  return lan;
});
const userBankInfo = computed(() => {
  return parentData.value["bank-info"].some(
    (item) => !item.COUNTRY_CD || !item.BANK_CD || !item.ACCOUNT_NAME
  ); //|| !item.field2|| !item.field2
});
// 数据请求接口
const urlLogin = "/api/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/";
const { requestPost } = request.methods;
const valid = () => {
  let a = parentData.value["edu-exp"].filter((i) => {
    return i.DC_EDU_EDUCATION == "23";
  });

  let b = parentData.value["edu-exp"].filter((i) => {
    return i.DC_EDU_EDUCATION == "22";
  });

  let c = parentData.value["edu-exp"].filter((i) => {
    return i.DC_EDU_EDUCATION == "21";
  });
  let aa = parentData.value["edu-exp"].filter((i) => {
    return i.DC_IS_HIGH_EDU == "Y";
  });
  if (aa.length > 1 || aa.length < 1) {
    ElMessageBox.alert("必须且只能存在一份最高学历", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  let bb = parentData.value["edu-exp"].filter((i) => {
    return i.DC_IS_HIGH_DEGREE == "Y";
  });
  if (bb.length > 1 || bb.length < 1) {
    ElMessageBox.alert("必须且只能存在一份最高学位", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  if (a.length > 0) {
    a.map((i) => {
      if (
        new Date(parentData.value["base-info"].START_DT_CHN) <
        new Date(i.END_DT)
      ) {
        ElMessageBox.alert("参加工作时间不能早于大专毕业日期", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }
    });
  }
  if (b.length > 0) {
    b.map((i) => {
      if (
        new Date(parentData.value["base-info"].START_DT_CHN) <
        new Date(i.END_DT)
      ) {
        ElMessageBox.alert("参加工作时间不能早于专升本毕业日期", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }
    });
  }
  if (c.length > 0) {
    b.map((i) => {
      if (
        new Date(parentData.value["base-info"].START_DT_CHN) <
        new Date(i.END_DT)
      ) {
        ElMessageBox.alert("参加工作时间不能早于本科毕业日期", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }
    });
  }
  let ee = parentData.value["professional-qual"].filter((i) => {
    return i.DC_ISTOPLV == "Y";
  });
  if (ee.length > 1) {
    ElMessageBox.alert("只能存在一个最高等级", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  if (
    parentData.value["base-info"].HUKOU_TYPE_CHN == "LFARMER" ||
    parentData.value["base-info"].HUKOU_TYPE_CHN == "LPRESIDENT"
  ) {
    if (
      parentData.value["base-info"].CONTRIB_AREA_CHN !==
      parentData.value["base-info"].LOCATION
    ) {
      ElMessageBox.alert("请确认户口类别是否正确", "提交失败", {
        confirmButtonText: "确认",
      });
    }
  } else {
    if (
      parentData.value["base-info"].CONTRIB_AREA_CHN ==
      parentData.value["base-info"].LOCATION
    ) {
      ElMessageBox.alert("请确认户口类别是否正确", "提交失败", {
        confirmButtonText: "确认",
      });
      return false;
    }
  }
  let d = parentData.value["relative"].filter((i) => {
    return i.DC_YES_OR_NO == "Y";
  });
  if (d.length > 1 || d.length < 1) {
    ElMessageBox.alert("必须且只能填一个紧急联系人", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }

  if (d.length == 1 && d[0].DC_YES_OR_NO == "Y" && !d[0].PHONE) {
    ElMessageBox.alert("紧急联系人手机号必填", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  let bi = parentData.value["bank-info"].filter((i) => {
    return (
      i.ACCOUNT_NAME !==
      parentData.value["base-info"].LAST_NAME +
        parentData.value["base-info"].FIRST_NAME
    );
  });
  // if (bi.length > 0) {
  //   alert(`开户人与本人名字必须一致`);
  //   return;
  // }

  if (basicInfo.value) {
    ElMessageBox.alert("基本信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (
    !/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
      parentData.value["base-info"].NATIONAL_ID
    )
  ) {
    ElMessageBox.alert("基本信息身份证号格式不对", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (
    !/^(?:\+86)?1[3-9]\d{9}$/.test(parentData.value["base-info"].PHONE)
  ) {
    ElMessageBox.alert("基本信息手机号格式不对", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else if (
    !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
      parentData.value["base-info"].EMAIL_ADDR
    )
  ) {
    ElMessageBox.alert("基本信息邮箱格式不对", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }

  if (familyInfo.value) {
    ElMessageBox.alert("亲属信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }else{
    for(let i = 0;i<parentData.value["relative"].length;i++){
      if (
        !/^(?:\+86)?1[3-9]\d{9}$/.test(
          parentData.value["relative"][i].PHONE
        )
      ) {
        ElMessageBox.alert("亲属信息电话格式不对", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }else{
        if(parentData.value["relative"][i].LAST_NAME == parentData.value["base-info"].LAST_NAME && parentData.value["relative"][i].FIRST_NAME == parentData.value["base-info"].FIRST_NAME)
        ElMessageBox.alert("亲属的姓名不能和本人姓名一致，特殊情况请联系HR", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }
    }
  }
  if (addressInfo.value) {
    ElMessageBox.alert("地址信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  if (beforeWorkInfo.value) {
    ElMessageBox.alert("以前工作经历有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  } else{
    for(let i=0;i<parentData.value["work-exp"].length ;i++){
      if (
        !/^(?:\+86)?1[3-9]\d{9}$/.test(
          parentData.value["work-exp"][i].PHONE
        )
      ) {
        ElMessageBox.alert("工作经历证明人手机号格式不对", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }else if(parentData.value["work-exp"][i].END_DT && parentData.value["work-exp"][i].START_DT > parentData.value["work-exp"][i].END_DT){
        ElMessageBox.alert("工作经历开始日期不能大于结束日期", "提交失败", {
          confirmButtonText: "确认",
        });
        return false;
      }
    }
  }
  
  if (educationInfo.value) {
    ElMessageBox.alert("教育经历有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }else{
    for(let i =0;i<parentData.value['edu-exp'].length;i++){
      if(parentData.value['edu-exp'][i].BEGIN_DT>parentData.value['edu-exp'][i].END_DT){
        ElMessageBox.alert("教育经历入学时间不能大于毕业时间", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
      }
    }
  }
  if (jobTitileInfo.value) {
    ElMessageBox.alert("职称信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  let qua = parentData.value["professional-qual"].filter((i) => {
    return i.DC_ISTOPLV == "Y";
  });
  if (jobQualification.value) {
    ElMessageBox.alert("职业资格有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }else{
    for(let i = 0;i<parentData.value['professional-qual'].length;i++)
      if(parentData.value['professional-qual'][i].DC_PRQUID &&( qua.length>1 || qua.length<1)){
    ElMessageBox.alert("必须且只能有一个最高等级职业资格", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
    }
  

  }
  if (userBankInfo.value) {
    ElMessageBox.alert("银行账户信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  if (rpInfo.value) {
    ElMessageBox.alert("奖惩信息有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  if (language.value) {
    ElMessageBox.alert("语言能力有未填字段", "提交失败", {
      confirmButtonText: "确认",
    });
    return false;
  }
  return true;
};
const submitForm = () => {
  if (!store.state.isSubmitButtonClicked) {
    ElMessageBox.alert("请先点击 -保存附件- 按钮", "警告", {
      confirmButtonText: "返回",
    });
  }
  let input_flag = true;
  input_flag = valid();
  parentData.value["relative"].forEach((item, i) => {
    // item
    item.DEPENDENT_BENEF = `${i + 1}`;
  });
  parentData.value["edu-exp"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  if (parentData.value["address-info"].length < 3) {
    ElMessageBox.alert(
      "地址信息中需添加“工作地居住地址、法定送达地址、身份证地址”的信息",
      "警告",
      {
        confirmButtonText: "确认",
      }
    );
    return;
  }
  parentData.value["bank-info"].forEach((item, i) => {
    item.ACCOUNT_ID = `${i + 1}`;
  });
  parentData.value["work-exp"].forEach((item, i) => {
    item.SEQUENCE_NBR = `${i + 1}`;
  });
  parentData.value["language"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  parentData.value["job-title"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  parentData.value["professional-qual"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  parentData.value["reward-punishment"].forEach((item, i) => {
    item.SEQNO = `${i + 1}`;
  });

  // // 假设获取数据的逻辑是异步的
  const requestBody = {
    language: "ZHS",
    appversion: "1.0",
    devicetype: "PC",
    deviceid: "35d1298095ac0ef1",
    devicever: "PC",
    infmod: "RC",
    infid: "SUBMIT",
    biz: {
      // id: "1b7IbEBvhDs=",
      id: parentData.value["base-info"].DC_CANDDT_ID,
      action: "S",
      form: parentData.value,
    },
  };
  const requestBody_file = {
    language: "ZHS",
    appversion: "1.0",
    devicetype: "PC",
    deviceid: "Extranet registration",
    devicever: "V1",
    infmod: "RC",
    infid: "GETATTACH",
    biz: {
      // id: "1b7IbEBvhDs=",
      id: parentData.value["base-info"].DC_CANDDT_ID,
    },
  };
  let file_flag = true;
  requestPost(urlLogin, requestBody_file, "0000")
    .then((back) => {
      let allUploadList = back.response.data.returnData.attachInfo;

      // let a = allUploadList.filter((i) => i.attachType == "20");
      // if (a.length == 0) {
      //   alert("请上传文件");
      //   return;
      // }

      let b = allUploadList.filter((i) => i.attachType == "10");
      if (b.length == 0) {
        ElMessageBox.alert("请上传身份证正面", "提交失败", {
          confirmButtonText: "确认",
        });
        file_flag = false;
        return file_flag;
      }
      let c = allUploadList.filter((i) => i.attachType == "23");
      if (c.length == 0) {
        ElMessageBox.alert("请上传身份证反面", "提交失败", {
          confirmButtonText: "确认",
        });
        file_flag = false;
        return file_flag;
      }
      if (parentData.value["base-info"].FLAG1 == "Y") {
        let d = allUploadList.filter((i) => i.attachType == "18");
        if (d.length == 0) {
          ElMessageBox.alert("请上传职称信息", "提交失败", {
            confirmButtonText: "确认",
          });
          file_flag = false;
          return file_flag;
        }
      }
      if (parentData.value["base-info"].FLAG == "Y") {
        let e = allUploadList.filter((i) => i.attachType == "19");
        if (e.length == 0) {
          ElMessageBox.alert("请上传语言能力", "提交失败", {
            confirmButtonText: "确认",
          });
          file_flag = false;
          return file_flag;
        }
      }
      let f = allUploadList.filter((i) => i.attachType == "12");
      if (f.length !== parentData.value["bank-info"].length) {
        ElMessageBox.alert("请上传银行附件", "提交失败", {
          confirmButtonText: "确认",
        });
        file_flag = false;
        return file_flag;
      }
      console.log(file_flag,input_flag);
      
      if (file_flag && input_flag) {
        //  文件验证过后在提交
        requestPost(urlLogin, requestBody, "0000")
          .then((back) => {
            // selectList.value = back.response.data.returnData
            // store.dispatch('storeValue', selectList.value);
            ElMessageBox.alert("提交成功", "提交完成", {
              confirmButtonText: "确认",
            }).then(() => {
              router.push("/login");
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })
    .catch((error) => {
      console.log(error);
    });

  // requestPost(urlLogin, requestBody1, "0000")
  //   .then((back) => {
  //     // selectList.value = back.response.data.returnData
  //     // store.dispatch('storeValue', selectList.value);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
};

const save = () => {
  let flag = false;
  if (addressInfo_save.value) {
    ElMessageBox.alert("至少填写 地址类型 字段", "警告", {
      confirmButtonText: "返回",
    });
  }
  if (beforeWorkInfo_save.value) {
    ElMessageBox.alert("至少填写 工作经历-开始时间 字段", "警告", {
      confirmButtonText: "返回",
    });
    return;
  }
  if (educationInfo_save.value) {
    ElMessageBox.alert("至少填写 教育信息-开始时间 字段", "警告", {
      confirmButtonText: "返回",
    });
    return;
  }
  if (familyInfo_save.value) {
    ElMessageBox.alert("至少填写 亲属信息-姓和名 字段", "警告", {
      confirmButtonText: "返回",
    });
    console.log(familyInfo_save.value);
    return;
  }
  if (userBankInfo_save.value) {
    ElMessageBox.alert("至少填写 银行账户-银行账号 和 银行分行 字段", "警告", {
      confirmButtonText: "返回",
    });
    return;
  }

  parentData.value["relative"].forEach((item, i) => {
    item.DEPENDENT_BENEF = `${i + 1}`;
  });
  parentData.value["edu-exp"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  // parentData.value["address-info"].forEach((item,i) => {
  //   item.DEPENDENT_BENEF = `${i+1}`
  // });
  parentData.value["bank-info"].forEach((item, i) => {
    item.ACCOUNT_ID = `${i + 1}`;
  });
  parentData.value["work-exp"].forEach((item, i) => {
    item.SEQUENCE_NBR = `${i + 1}`;
  });
  for(let i=0;i<parentData.value["language"].length;i++){
    if (!parentData.value["language"][i].DC_PROVE_ID) {
      ElMessageBox.alert("语言能力中“证书等级”未填写”", "警告", {
        confirmButtonText: "返回",
      });
      flag = true;
      return;
    } else {
      parentData.value["language"][i].SEQNBR = `${i + 1}`;
    }
  }
  parentData.value["job-title"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  parentData.value["professional-qual"].forEach((item, i) => {
    item.SEQNBR = `${i + 1}`;
  });
  parentData.value["reward-punishment"].forEach((item, i) => {
    item.SEQNO = `${i + 1}`;
  });
  const requestBody = {
    language: "ZHS",
    appversion: "1.0",
    devicetype: "PC",
    deviceid: "35d1298095ac0ef1",
    devicever: "PC",
    infmod: "RC",
    infid: "SUBMIT",
    biz: {
      id: parentData.value["base-info"].DC_CANDDT_ID,
      action: "V",
      form: parentData.value,
    },
  };
  if (!flag) {
    requestPost(urlLogin, requestBody, "0000")
      .then((back) => {
        ElMessageBox.alert("保存成功", "成功", {
          confirmButtonText: "返回",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
};
</script>
<style scoped>
.blink-text {
  font-size: var(--pc-text-size-title);
  font-weight: bold;
  color: #000000; /* 文字颜色 */
  animation: blinkAnimation 1s infinite; /* 调用 blinkAnimation 动画 */
}
@keyframes blinkAnimation {
  0% {
    opacity: 1; /* 不透明 */
  }
  50% {
    opacity: 0; /* 半透明 */
  }
  100% {
    opacity: 1; /* 不透明 */
  }
}
.content-box {
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 90vh;
  width: 88%;
  background-color: var(--bg-color);
  padding: 10px;
}

.content {
  background-color: var(--bg-content-color);
  height: 90%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding: 15px; */
}

.buttons-box {
  display: flex;
  gap: 80px;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-content-color);
  flex-wrap: wrap;

  height: 10%;
  width: 100%;
}
</style>
