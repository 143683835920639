
import {createApp} from 'vue';
import App from './App.vue'

//引入store
import store from './store/index'
import router from './router'

//粒子特效库
import Particles from "particles.vue3"; 
//antd-vue库
import  antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';

//axios
import axios from 'axios';

//LAYUI
import Layui from '@layui/layui-vue'
import '@layui/layui-vue/lib/index.css'
//ELE
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
//
const app = createApp(App);
//挂在全局axios实例
app.provide('axios', axios);
//应用插件
app.use(router) 
app.use(store)
app.use(Particles)
app.use(antd);
app.use(Layui);
app.use(ElementPlus);
app.config.productionTip = false;
app.mount('#app');


