<template>
  <a-tabs
    v-model:activeKey="activeKey"
    type="editable-card"
    @edit="onEdit"
    @change="handleTabChange"
  >
    <a-tab-pane
      v-for="(pane, ind) in panes"
      :key="pane.key"
      :tab="pane.title"
      :closable="pane.closable"
    >
      <form class="form">
        <div class="input-box">
          <span class="span-tit">*职业资格</span>
          <el-select
            value=""
            v-model="resLogin.form['professional-qual'][ind].DC_PRQUID"
            placeholder="请选择"
          >
            <el-option
              :value="option.dc_prquid"
              v-for="(option, index) in selectList.prqu_list"
              :key="index"
              :label="option.dc_qualifi"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <!-- <div class="input-box">
          <span class="span-tit">*资格类别</span>
          <el-select
            value=""
            v-model="resLogin.form['professional-qual'][ind].DC_QUALIFICATE"
            disabled
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.qual_class"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div> -->
        <div class="input-box">
          <span class="span-tit">*资格级别</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['professional-qual'][ind].DC_QUALIFILV"
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.qual_level"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*评定成绩</span>
          <el-input
            type="text"
            v-model="resLogin.form['professional-qual'][ind].DC_GRADING"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*理论成绩</span>
          <el-input
            type="text"
            v-model="resLogin.form['professional-qual'][ind].DC_THRTCGRAD"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>

        <div class="input-box">
          <span class="span-tit">*操作成绩</span
          ><el-input
            type=""
            v-model="resLogin.form['professional-qual'][ind].DC_OPERGRAD"
          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*是否最高等级</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['professional-qual'][ind].DC_ISTOPLV"
          >
            <el-option
              :value="option.fieldvalue"
              v-for="(option, index) in selectList.ishighest_quallevel"
              :key="index"
              :label="option.xlatshortname"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">证书编号</span>
          <el-input
            type="text"
            v-model="resLogin.form['professional-qual'][ind].DC_CERTIFINO"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">发证时间</span>
          <el-input
            type="date"
            v-model="resLogin.form['professional-qual'][ind].DATE"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">发证单位</span>
          <el-input
            type="text"
            v-model="resLogin.form['professional-qual'][ind].COMPANY_NAME"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">有效期截止</span>
          <el-input
            type="date"
            v-model="resLogin.form['professional-qual'][ind].DATE1"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit" style="width: 150px">备注</span>
          <el-mention
            maxlength="254"
            type="textarea"
            style="resize: horizontal"
            v-model="resLogin.form['professional-qual'][ind].COMMENTS_256"
          ></el-mention>
          <span class="descr"></span>
        </div>
      </form>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { useStore } from "vuex";
import { ref } from "vue";
import { computed, watch } from "vue";
import { onMounted } from "vue";
import { common } from "../mixin/common";
import { request } from "../mixin/axios";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "family",
  props: {},
  mixins: [common],
  setup() {
    const urlLogin =
      "/api/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/";
    const { requestPost } = request.methods;

    const panes = ref([]);
    const store = useStore();

    //取值state
    const resLogin = computed(() => {
      return store.state.userInfo.resLogin;
    });
    //取值state
    const selectList = computed(() => {
      return store.state.myValue;
    });
    const tabsIndex = ref(0);
    const handleTabChange = (key, event) => {
      tabsIndex.value = key - 1;
      // selectData();
    };

    onMounted(() => {});

    // layui标签页
    let index = 1; //职业资格编号
    // ============根据返回的数据看数组的长度在页面上遍历页签，空数组的话就自动生成一个页签==
    if (resLogin.value.form["professional-qual"].length == 0) {
      panes.value.push({
        title: `职业资格`,
        content: "",
        key: `${index}`,
      });
      index = index + 1;
    } else {
      //遍历所有数据，有几条数据就生成几个tab
      for (
        let i = 0;
        i < resLogin.value.form["professional-qual"].length;
        i++
      ) {
        if (i == 0) {
          panes.value.push({
            title: `职业资格`,
            content: "",
            key: `${index}`,
            closable: false,
          });
        } else {
          panes.value.push({
            title: `职业资格`,
            content: "",
            key: `${index}`,
          });
        }
        index = index + 1;
      }
    }
    //=================

    const activeKey = ref(panes.value[0].key);
    const newTabIndex = ref(0);
    const quacate = ref("");
    //添加页签
    const add = () => {
      let professional_qual = {
        DC_CANDDT_ID: resLogin.value.form["base-info"].DC_CANDDT_ID,
        SEQNBR: "",
        DC_PRQUID: "",
        DC_QUALIFILV: "",
        DC_GRADING: "",
        DC_THRTCGRAD: "",
        DC_OPERGRAD: "",
        DC_ISTOPLV: "",
        DC_CERTIFINO: "",
        DATE: "",
        COMPANY_NAME: "",
        DATE1: "",
        COMMENTS_256: "",
      };
      activeKey.value = index;
      panes.value.push({
        title: `职业资格`,
        content: "",
        key: activeKey.value,
      });
      resLogin.value.form["professional-qual"].push(professional_qual);
      console.log(resLogin.value.form["professional-qual"]);
      index = index + 1;
      // resLogin.value.form["relative"][index].DEPENDENT_BENEF = `${index}`;
      store.dispatch("userInfo/setUserData", resLogin);
    };
    //删除页签
    const remove = (targetKey) => {
      let lastIndex = 0;
      panes.value.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      panes.value = panes.value.filter((pane) => pane.key !== targetKey);
      if (panes.value.length && activeKey.value === targetKey) {
        if (lastIndex >= 0) {
          activeKey.value = panes.value[lastIndex].key;
        } else {
          activeKey.value = panes.value[0].key;
        }
      }
      index = index - 1;
      resLogin.value.form["professional-qual"].splice(targetKey - 1, 1);

      store.dispatch("userInfo/setUserData", resLogin);
    };
    const onEdit = (targetKey, action) => {
      console.log("on", targetKey);

      if (action === "add") {
        add();
      } else {
        remove(targetKey);
      }
    };

    // selectList.value.prqu_list.map((item) => {
    //   if (
    //     item.dc_prquid ==
    //     resLogin.value.form["professional-qual"][activeKey.value - 1].DC_PRQUID
    //   ) {

    //     quacate.value[activeKey.value - 1] = item.dc_qualificate == "10-准入类" ? "10" : "20";
    //   }
    // });

    // watch(
    //   () =>
    //     resLogin.value.form["professional-qual"][activeKey.value - 1].DC_PRQUID,
    //   (newVal) => {
    //     console.log(newVal, "newValnewValnewValnewValnewVal1111");
    //     selectList.value.prqu_list.map((item) => {
    //       if (
    //         item.dc_prquid ==
    //         resLogin.value.form["professional-qual"][activeKey.value - 1]
    //           .DC_PRQUID
    //       ) {
    //         resLogin.value.form["professional-qual"][
    //           activeKey.value - 1
    //         ].DC_QUALIFICATE = item.dc_qualificate == "10-准入类" ? "10" : "20";
    //       }
    //     });
    //   }
    // );

    store.commit("setChildData", resLogin.value.form);
    //删除附件
    const file_file = ref("");
    const delFile = () => {
      //........
    };
    return {
      resLogin,
      index,
      add,
      remove,
      onEdit,
      panes,
      file_file,
      delFile,
      selectList,
      handleTabChange,
      tabsIndex,
    };
  },
};
</script>
<style scoped>
/* 自己的样式 */
.span-tit {
  width: 60px;
  /* display: flex;
  justify-content: end; */
  line-height: 17px;
}
.descr {
  width: 195px;
  word-wrap: break-word;
  line-height: 17px;
  margin-left: 5px;
}
.form {
  font-size: var(--pc-text-size-title);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  line-height: 45px;
  padding: 15px;
  /* padding-right: 50px; */

  .file-box {
    width: 100%;
  }

  .input-box {
    /* min-width: 100px; */
    width: calc(100% / 3);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 0 3px 0;

    .span-tit {
      width: 150px;
      /* display: flex;
      justify-content: end; */
      line-height: 17px;
    }

    input,
    select {
      /* width: 200px; */
      border: 1px solid rgb(199, 199, 199);
      height: 25px;
      border-radius: 3px;
      font-size: 12px;
      margin-left: 5px;

      /* -webkit-appearance:unset !important ; */
      option {
        height: 25px;
      }
    }

    .descr {
      width: 195px;
      word-wrap: break-word;
      line-height: 17px;
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 768px) {
  /* 手机端适配 */
  .form {
    gap: 10px;

    .input-box {
      gap: 10px;
      width: 100%;

      .span-tit {
        width: 40%;
      }

      input,
      select {
        width: 80%;
        height: 20px;
        font-size: 10px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* 平板电脑的样式 */
}
</style>
