<template>
  <a-tabs
    v-model:activeKey="activeKey"
    type="editable-card"
    @edit="onEdit"
    @change="handleTabChange"
    hideAdd

  >
    <a-tab-pane
      v-for="(pane, ind) in panes"
      :key="pane.key"
      :tab="pane.title"
      :closable="pane.closable"
    >
      <form class="form">
        <div class="input-box">
          <span class="span-tit">*国家/地区代码</span>
          <el-select
           placeholder="请选择"
            v-model="resLogin.form['bank-info'][ind].COUNTRY_CD"
          >
            <el-option
              :value="option.country"
              v-for="(option, index) in selectList.country"
              :key="index"
              :label="option.descr"
              :default="chn"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*银行</span>
          <el-select placeholder="请选择" v-model="resLogin.form['bank-info'][ind].BANK_CD">
            <el-option
              :value="option.bank_cd"
              v-for="(option, index) in selectList.bank_list"
              :key="index"
              :label="option.bank_nm"
            >
            </el-option>
          </el-select>
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*银行分行</span>
          <el-autocomplete
            v-model="searchText[tabsIndex]"
            :fetch-suggestions="querySearch"
            class="inline-input w-50"
            placeholder="支持输入查询"
            @select="handleSelect(ind, $event)"
            :value-key="value"
            submit-on-enter
            clearable
            :trigger-on-focus="false"
          />
          <span class="descr"></span>
        </div>

        <div class="input-box">
          <span class="span-tit">*帐号</span
          ><el-input
            type=""
            v-model="resLogin.form['bank-info'][ind].ACCOUNT_EC_ID"
          /><span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">*开户名</span>
          <el-input
            type=""
            v-model="resLogin.form['bank-info'][ind].ACCOUNT_NAME"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">币种</span>
          <el-select
            placeholder="请选择"
            v-model="resLogin.form['bank-info'][ind].ACCOUNT_NAME"
            
          >
            <el-option
              :value="option.currency_cd"
              v-for="(option, index) in selectList.currency_list"
              :key="index"
              :label="option.descr"
            >
            </el-option>
          </el-select>
       
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">联行号</span>
          <el-input
            type="text"
            v-model="resLogin.form['bank-info'][ind].DC_BANK_CODE"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>
        <div class="input-box">
          <span class="span-tit">Swift Code</span>
          <el-input
            type="text"
            v-model="resLogin.form['bank-info'][ind].DC_SWIFT_CODE"
            maxlength="30"
          />
          <span class="descr"></span>
        </div>

      </form>
    </a-tab-pane>
  </a-tabs>
</template>
<script>
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import { computed, watch } from "vue";
import { common } from "../mixin/common";
import { request } from "../mixin/axios";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  name: "family",
  props: {},
  mixins: [common],
  setup() {
    // 数据请求接口
    const urlLogin =
      "/api/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/";
    const { requestPost } = request.methods;
const cny = "CNY";
const chn = "CHN";
const closeAble = false;
    //文件取值
    const searchText = ref([]);

    onMounted(() => {
      
    });

    const restaurants = ref([{}]);
    const querySearch = (queryString, cb) => {
      const results = queryString
        ? restaurants.value.filter((option) =>
            option.value.includes(queryString)
          )
        : restaurants.value;
      cb(results);
    };
    const loadAll = () => {
      return bankList.value;
    };
    const handleSelect = (index, item) => {
      console.log(item, "======", index);
      searchText.value[index] = item.value;
      resLogin.value.form["bank-info"][index].BRANCH_EC_CD = item.branch_ec_cd;
    };

    // 数据请求接口
    const bankList = ref();
    const selectData = (val, val2) => {
      store.dispatch("setLoading", true);
      console.log("当前标签下标：", tabsIndex.value);
      //登录
      const requestBody = {
        language: "ZHS",
        appversion: "1.0",
        devicetype: "PC",
        deviceid: "35d1298095ac0ef1",
        devicever: "V1",
        infmod: "XLAT",
        infid: "BRANCHBANKLIST",
        biz: {
          country_cd: val
            ? val
            : resLogin.value.form["bank-info"][tabsIndex.value].COUNTRY_CD,
          bank_cd: val2
            ? val2
            : resLogin.value.form["bank-info"][tabsIndex.value].BANK_CD,
        },
      };
      requestPost(urlLogin, requestBody, "0000")
        .then((back) => {
          bankList.value = back.response.data.returnData.bank_branch_list;

          bankList.value.forEach((ele) => {
            ele.value = ele.xlatshortname;
          });
          restaurants.value = loadAll();
          bankList.value.forEach((ele) => {
            ele.value = ele.descr;
          });

          bankList.value.filter((i) => {
            if (
              i.branch_ec_cd ==
              resLogin.value.form["bank-info"][tabsIndex.value].BRANCH_EC_CD
            ) {
              searchText.value[tabsIndex.value] = i.descr;
            }
          });

          if (back.response.status == 200) {
            store.dispatch("setLoading", false);
          }
        })
        .catch((error) => {
          store.dispatch("setLoading", false);
          ElMessageBox.alert("获取银行列表出错", "错误", {
            confirmButtonText: "确认",
          });
        });
    };

    const panes = ref([]);
    const store = useStore();
    //取值state
    const resLogin = computed(() => {
      return store.state.userInfo.resLogin;
    });
    //取值state
    const selectList = computed(() => {
      return store.state.myValue;
    });
    const tabsIndex = ref(0);
    const handleTabChange = (key, event) => {
      tabsIndex.value = key - 1;
      selectData();
      // getData();
    };

    const getData = () => {
    };

    watch(
      () => [
        resLogin.value.form["bank-info"][tabsIndex.value].COUNTRY_CD,
        resLogin.value.form["bank-info"][tabsIndex.value].BANK_CD,
      ],
      ([val, val2]) => {
        console.log("姓名是:", val, "每页条数是:", val2);
        if (val && val2) {
          selectData(val, val2);
        }
      }
    );

    // layui标签页
    let index = 1; //账户信息编号
    // ============根据返回的数据看数组的长度在页面上遍历页签，空数组的话就自动生成一个页签==
    if (resLogin.value.form["bank-info"].length == 0) {
      panes.value.push({
        title: `账户信息`,
        content: "",
        key: `${index}`,
        ind: index - 1,
        closable:false
      });
      index = index + 1;
    } else {
      //遍历所有数据，有几条数据就生成几个tab
      for (let i = 0; i < resLogin.value.form["bank-info"].length; i++) {
        panes.value.push({
          title: `账户信息`,
          content: "",
          key: `${index}`,
          ind: index - 1,
          closable:false
        });
        index = index + 1;
      }
    }
    //=================

    const activeKey = ref(panes.value[0].key);
    const newTabIndex = ref(0);
    //添加页签
    const add = () => {
      let bank_info = {
        DC_CANDDT_ID: resLogin.value.form["base-info"].DC_CANDDT_ID,
        ACCOUNT_ID: "",
        COUNTRY_CD: "",
        BANK_CD: "",
        BRANCH_EC_CD: "",
        ACCOUNT_EC_ID: "",
        ACCOUNT_NAME: "",
        CURRENCY_CD: "",
        DC_BANK_CODE: "",
        DC_SWIFT_CODE: "",
      };

      activeKey.value = index;
      panes.value.push({
        title: `账户信息`,
        content: "",
        key: activeKey.value,
      });
      resLogin.value.form["bank-info"].push(bank_info);
      console.log(resLogin.value.form["bank-info"]);
      index = index + 1;
      // resLogin.value.form["relative"][index].DEPENDENT_BENEF = `${index}`;
      store.dispatch("userInfo/setUserData", resLogin);
    };
    //删除页签
    const remove = (targetKey) => {
      let lastIndex = 0;
      panes.value.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1;
        }
      });
      panes.value = panes.value.filter((pane) => pane.key !== targetKey);
      if (panes.value.length && activeKey.value === targetKey) {
        if (lastIndex >= 0) {
          activeKey.value = panes.value[lastIndex].key;
        } else {
          activeKey.value = panes.value[0].key;
        }
      }
      index = index - 1;
      resLogin.value.form["bank-info"].splice(targetKey - 1, 1);

      store.dispatch("userInfo/setUserData", resLogin);
    };
    const onEdit = (targetKey, action) => {
      console.log("on", targetKey);

      if (action === "add") {
        add();
      } else {
        remove(targetKey);
      }
    };

    store.commit("setChildData", resLogin.value.form);
    // selectData();
    return {
      cny,
      chn,
      closeAble,
      resLogin,
      index,
      add,
      remove,
      onEdit,
      panes,
      selectList,
      selectData,
      handleTabChange,
      tabsIndex,
      bankList,
      restaurants,
      querySearch,
      loadAll,
      handleSelect,
      searchText,
      getData,
    };
    // console.log(store.state.userInfo.resLogin,"resLogin")
  },
};
</script>
<style scoped>
/* 自己的样式 */
.span-tit {
  width: 60px;
  display: flex;
  justify-content: end;
  line-height: 17px;
}
.descr {
  width: 195px;
  word-wrap: break-word;
  line-height: 17px;
  margin-left: 5px;
}
.form {
  font-size: var(--pc-text-size-title);
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  line-height: 45px;
  padding: 15px;
  /* padding-right: 50px; */

  .file-box {
    width: 100%;
  }

  .input-box {
    /* min-width: 100px; */
    width: calc(100% / 3);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 3px 0 3px 0;

    .span-tit {
      width: 150px;
      display: flex;
      justify-content: end;
      line-height: 17px;
    }

    input,
    select {
      width: 200px;
      border: 1px solid rgb(199, 199, 199);
      height: 25px;
      border-radius: 3px;
      font-size: 12px;
      margin-left: 5px;

      /* -webkit-appearance:unset !important ; */
      option {
        height: 25px;
      }
    }

    .descr {
      width: 195px;
      word-wrap: break-word;
      line-height: 17px;
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 768px) {
  /* 手机端适配 */
  .form {
    gap: 10px;

    .input-box {
      gap: 10px;
      width: 100%;

      .span-tit {
        width: 40%;
        display: block;
      }

      input,
      select {
        width: 80%;
        height: 20px;
        font-size: 10px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  /* 平板电脑的样式 */
}
</style>
