<template>
  <div class="index">
    <div
      class="item link"
      v-for="(item, index) in menuList"
      :key="index"
      :class="{ active: isActive }"
      @click="goPath(item)"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { request } from "../mixin/axios";
import { useStore } from "vuex";
const store = useStore();
// 数据请求接口
const urlLogin = "/api/PSIGW/RESTListeningConnector/PSFT_HR/GPS_PSP_INF.v1/";
const { requestPost } = request.methods;
const router = useRouter();

const isActive = ref(false);
const menuList = ref([
  {
    name: "基本信息",
    path: "/all/basicInfo",
  },
  {
    name: "亲属信息",
    path: "/all/family",
  },
  {
    name: "教育信息",
    path: "/all/education",
  },
  {
    name: "地址信息",
    path: "/all/address",
  },
  {
    name: "银行账户",
    path: "/all/userBank",
  },
  {
    name: "工作经历",
    path: "/all/beforeWork",
  },
  {
    name: "语言能力",
    path: "/all/language",
  },

  {
    name: "职称信息",
    path: "/all/jobTitle",
  },
  {
    name: "职业资格",
    path: "/all/jobQualifcation",
  },

  {
    name: "奖惩信息",
    path: "/all/rp",
  },
  {
    name: "附件详情",
    path: "/all/attach",
  },

  // {
  //   name:"附件信息",
  //   path:"/all/file"
  // },
]);
const goPath = (item) => {
  store.dispatch("setLoading", true);
  // let store = this.$store.state.userInfo.resLogin;
  // console.log(store,"storestorestorestorestore")
  // // this.$store.dispatch("userInfo/setUserData", store);
  //    //获取附件信息
  const requestBody = {
    language: "ZHS",
    appversion: "1.0",
    devicetype: "PC",
    deviceid: "35d1298095ac0ef1",
    devicever: "V1",
    infmod: "RC",
    infid: "GETATTACH",
    biz: {
      // id: "1b7IbEBvhDs",
      id: store.state.userInfo.resLogin.form["base-info"].DC_CANDDT_ID,
    },
  };
  requestPost(urlLogin, requestBody, "0000")
    .then((back) => {
      let a = back.response.data.returnData.attachInfo;
      store.dispatch("storeImgFileValue", a);

      router.push(item.path);
      store.dispatch("setLoading", false); // 结束 loading
    })
    .catch((error) => {
      console.log(error);
      store.dispatch("setLoading", false); // 结束 loading
    });
};
</script>

<style scoped>
.link {
  display: block;
  width: 100%;
  height: 20px;
  padding: 15px 0px;
  text-align: center;
  /* background-color: rgb(255, 145, 0); */
  flex-shrink: 0;
  /* border-bottom: 5px solid rgba(0, 0, 0, 0.208); */
  font-size: var(--pc-text-size-title);
  box-sizing: content-box;
}
.link:hover {
  background-color: var(--header-bg-color);
  border-radius: 5px;
  color: white;
}
.active {
  background-color: var(--header-bg-color);
  border-radius: 5px;
  color: white;
}
</style>
